import React, { createContext, useContext, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { collection, query, onSnapshot, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../services/firebase';
import { notificationService } from '../services/notificationService';

const ChatContext = createContext();

// Load initial state from localStorage
const loadInitialState = () => {
  try {
    const savedState = localStorage.getItem('chatState');
    if (savedState) {
      const state = JSON.parse(savedState);
      return {
        currentUser: state.currentUser || {
          avatar: '',
          avatarType: '',
          objectif: '',
          age: '',
          civilite: '',
          pseudo: '',
          numero: ''
        },
        currentStep: state.currentStep || 'home',
        lastCheckTime: state.lastCheckTime ? new Date(state.lastCheckTime) : new Date()
      };
    }
  } catch (error) {
    console.error('Error loading initial state:', error);
  }
  return {
    currentUser: {
      avatar: '',
      avatarType: '',
      objectif: '',
      age: '',
      civilite: '',
      pseudo: '',
      numero: ''
    },
    currentStep: 'home',
    lastCheckTime: new Date()
  };
};

export function ChatProvider({ children }) {
  const initialState = useMemo(() => loadInitialState(), []);
  const [currentUser, setCurrentUser] = useState(initialState.currentUser);
  const [clients, setClients] = useState([]);
  const [messages, setMessages] = useState([]);
  const [historiques, setHistoriques] = useState([]);
  const [currentStep, setCurrentStep] = useState(initialState.currentStep);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [lastCheckTime, setLastCheckTime] = useState(initialState.lastCheckTime);
  const unsubscribesRef = useRef([]);
  const notifiedMessagesRef = useRef(new Set());

  // Save state to localStorage
  useEffect(() => {
    const state = {
      currentUser,
      currentStep,
      lastCheckTime: lastCheckTime?.toISOString()
    };
    localStorage.setItem('chatState', JSON.stringify(state));
  }, [currentUser, currentStep, lastCheckTime]);

  // Users listener with optimized updates
  const startUsersListener = useCallback(() => {
    if (!currentUser?.pseudo) return () => {};

    const q = query(collection(db, 'utilisateurs'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setClients(prevClients => {
        const clientsMap = new Map(prevClients.map(client => [client.pseudo, client]));
        let hasChanges = false;

        snapshot.docChanges().forEach((change) => {
          const userData = change.doc.data();
          
          if (userData.pseudo === currentUser.pseudo) return;

          if (change.type === 'added' || change.type === 'modified') {
            const existingClient = clientsMap.get(userData.pseudo);
            
            if (!existingClient || JSON.stringify(existingClient) !== JSON.stringify(userData)) {
              clientsMap.set(userData.pseudo, userData);
              hasChanges = true;

              // Check for new user notification
              const userCreatedAt = userData.createdAt?.seconds * 1000;
              if (change.type === 'added' && userCreatedAt && (!lastCheckTime || userCreatedAt > lastCheckTime.getTime())) {
                setNotifications(prev => [...prev, {
                  id: Date.now(),
                  type: 'user',
                  user: userData
                }]);
              }
            }
          } else if (change.type === 'removed') {
            if (clientsMap.has(userData.pseudo)) {
              clientsMap.delete(userData.pseudo);
              hasChanges = true;
            }
          }
        });

        return hasChanges ? Array.from(clientsMap.values()) : prevClients;
      });
    }, (error) => {
      console.error("Error in users listener:", error);
    });

    return () => {
      console.log('Unsubscribing from users listener');
      unsubscribe();
    };
  }, [currentUser?.pseudo, lastCheckTime]);

  // Notifications listener
  useEffect(() => {
    if (!currentUser?.pseudo) return;

    const checkMessages = async () => {
      try {
        const currentUserUpper = currentUser.pseudo.toUpperCase();
        const chatsRef = collection(db, 'chats');
        const chatsSnapshot = await getDocs(chatsRef);
        
        // Get all users for avatar lookup
        const usersRef = collection(db, 'utilisateurs');
        const usersSnapshot = await getDocs(usersRef);
        const usersMap = new Map();
        usersSnapshot.forEach(doc => {
          const userData = doc.data();
          if (userData.pseudo) {
            usersMap.set(userData.pseudo.toLowerCase(), userData);
          }
        });
        
        let hasNewMessages = false;
        
        // Clean up previous subscriptions
        unsubscribesRef.current.forEach(unsubscribe => unsubscribe());
        unsubscribesRef.current = [];
        
        for (const chatDoc of chatsSnapshot.docs) {
          const chatId = chatDoc.id;
          if (!chatId.includes(currentUserUpper)) continue;
          
          const otherParticipant = chatId
            .split('_')
            .find(p => p !== currentUserUpper)
            ?.toLowerCase();
            
          if (!otherParticipant) continue;
          
          const messagesRef = collection(db, 'chats', chatId, 'messages');
          const messagesQuery = query(messagesRef, orderBy('timestamp', 'desc'));
          
          const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              if (change.type === 'added') {
                const message = { id: change.doc.id, ...change.doc.data() };
                
                // Only show notification for new messages from others that we haven't notified about
                if (message.sender.toUpperCase() !== currentUserUpper && 
                    (!message.see || message.see === 0) &&
                    !notifiedMessagesRef.current.has(message.id)) {
                  hasNewMessages = true;
                  setIsNewMessage(true);
                  
                  // Add notification message if we're not chatting with the sender
                  if (currentStep !== 'chat' || 
                      !selectedClient || 
                      selectedClient.pseudo.toUpperCase() !== message.sender.toUpperCase()) {
                    // Get sender's data for the photo
                    const senderData = usersMap.get(message.sender.toLowerCase());
                    console.log('Sender data for notification:', senderData);
                    
                    setNotifications(prev => {
                      const newNotifications = [...prev, {
                        id: Date.now(),
                        type: 'message',
                        message: message.content,
                        sender: message.sender,
                        senderPhoto: senderData?.photoURL,
                        emoji: senderData?.objectif || '🍓' // Default to strawberry if no objectif
                      }];
                      console.log('Updated notifications:', newNotifications);
                      return newNotifications;
                    });
                  }
                  
                  // Mark this message as notified
                  notifiedMessagesRef.current.add(message.id);
                }
              }
            });
          });
          
          unsubscribesRef.current.push(unsubscribe);
        }
        
        if (!hasNewMessages) {
          setIsNewMessage(false);
        }
      } catch (error) {
        console.error('Error checking messages:', error);
      }
    };

    checkMessages();
    
    // Cleanup function to unsubscribe from all listeners
    return () => {
      console.log('Cleaning up message listeners');
      unsubscribesRef.current.forEach(unsubscribe => unsubscribe());
      unsubscribesRef.current = [];
    };
  }, [currentUser?.pseudo, currentStep, selectedClient]);

  // Clear notifications when entering chat with a user
  useEffect(() => {
    if (currentStep === 'chat' && selectedClient) {
      setIsNewMessage(false);
      // Remove notifications from this user
      setNotifications(prev => 
        prev.filter(n => 
          n.type !== 'message' || 
          n.sender.toUpperCase() !== selectedClient.pseudo.toUpperCase()
        )
      );
    }
  }, [currentStep, selectedClient]);

  // Clear notified messages when logging out
  useEffect(() => {
    if (!currentUser?.pseudo) {
      notifiedMessagesRef.current.clear();
    }
  }, [currentUser?.pseudo]);

  const removeNotification = useCallback((id) => {
    setNotifications(prev => prev.filter(n => n.id !== id));
  }, []);

  const goToChat = (pseudo) => {
    const client = { pseudo: pseudo };
    setSelectedClient(client);
    setCurrentStep('chat');
  };

  const contextValue = useMemo(() => ({
    currentUser,
    setCurrentUser,
    currentStep,
    setCurrentStep,
    selectedClient,
    setSelectedClient,
    isNewMessage,
    setIsNewMessage,
    notifications,
    setNotifications,
    removeNotification,
    goToChat,
    clients,
    setClients,
    messages,
    setMessages,
    historiques,
    setHistoriques,
    startUsersListener
  }), [
    currentUser,
    currentStep,
    selectedClient,
    isNewMessage,
    notifications,
    removeNotification,
    goToChat,
    clients,
    messages,
    historiques,
    startUsersListener
  ]);

  return (
    <ChatContext.Provider value={contextValue}>
      {children}
    </ChatContext.Provider>
  );
}

export function useChat() {
  return useContext(ChatContext);
}
