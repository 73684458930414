class DataCache {
  constructor() {
    this.cache = new Map();
    this.userCache = new Map();
    this.conversationsCache = new Map();
    this.TTL = 5 * 60 * 1000; // 5 minutes cache TTL
    this.initCache();
  }

  initCache() {
    // Recover existing cache from localStorage
    const savedCache = localStorage.getItem('dataCache');
    if (savedCache) {
      try {
        const { users, conversations, timestamp } = JSON.parse(savedCache);
        // Only restore cache if it's not expired
        if (timestamp && Date.now() - timestamp < this.TTL) {
          if (users) {
            Object.entries(users).forEach(([key, value]) => {
              this.userCache.set(key, value);
            });
          }
          if (conversations) {
            Object.entries(conversations).forEach(([key, value]) => {
              this.conversationsCache.set(key, value);
            });
          }
        } else {
          this.clearCache();
        }
      } catch (error) {
        console.error('Error parsing data cache:', error);
        localStorage.removeItem('dataCache');
      }
    }
  }

  saveCache() {
    const cacheData = {
      users: Object.fromEntries(this.userCache),
      conversations: Object.fromEntries(this.conversationsCache),
      timestamp: Date.now()
    };
    localStorage.setItem('dataCache', JSON.stringify(cacheData));
  }

  getUser(pseudo) {
    return this.userCache.get(pseudo?.toLowerCase());
  }

  setUser(pseudo, userData) {
    this.userCache.set(pseudo.toLowerCase(), userData);
    this.saveCache();
  }

  setUsers(users) {
    users.forEach(user => {
      this.userCache.set(user.pseudo.toLowerCase(), user);
    });
    this.saveCache();
  }

  getConversation(chatId) {
    return this.conversationsCache.get(chatId);
  }

  setConversation(chatId, conversationData) {
    this.conversationsCache.set(chatId, {
      ...conversationData,
      timestamp: Date.now()
    });
    this.saveCache();
  }

  clearCache() {
    this.userCache.clear();
    this.conversationsCache.clear();
    localStorage.removeItem('dataCache');
  }
}

export const dataCache = new DataCache();
