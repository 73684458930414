import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from './firebase';

class ImageCache {
  constructor() {
    this.cache = new Map();
    this.initCache();
  }

  initCache() {
    // Récupérer le cache existant du localStorage
    const savedCache = localStorage.getItem('imageCache');
    if (savedCache) {
      try {
        const parsedCache = JSON.parse(savedCache);
        Object.entries(parsedCache).forEach(([key, value]) => {
          this.cache.set(key, value);
        });
      } catch (error) {
        console.error('Error parsing image cache:', error);
        localStorage.removeItem('imageCache');
      }
    }
  }

  saveCache() {
    // Sauvegarder le cache dans localStorage
    const cacheObject = Object.fromEntries(this.cache);
    localStorage.setItem('imageCache', JSON.stringify(cacheObject));
  }

  async getImage(photoURL) {
    if (!photoURL) return null;

    // Si l'URL est déjà une URL complète (pas une référence Storage)
    if (photoURL.startsWith('http')) {
      return photoURL;
    }

    // Vérifier si l'image est dans le cache
    if (this.cache.has(photoURL)) {
      return this.cache.get(photoURL);
    }

    try {
      // Si pas dans le cache, récupérer depuis Firebase Storage
      const imageRef = ref(storage, photoURL);
      const url = await getDownloadURL(imageRef);
      
      // Mettre en cache
      this.cache.set(photoURL, url);
      this.saveCache();
      
      return url;
    } catch (error) {
      console.error('Error loading image:', error);
      return null;
    }
  }

  clearCache() {
    this.cache.clear();
    localStorage.removeItem('imageCache');
  }
}

export const imageCache = new ImageCache();
