import React, { useState, useEffect, useRef } from 'react';
import { useChat } from '../contexts/ChatContext';
import { db, storage } from '../services/firebase';
import { collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, setDoc, doc, getDoc, getDocs, writeBatch } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { imageCache } from '../services/imageCache';
import { dataCache } from '../services/dataCache';
import './ChatScreen.css';

const Message = ({ message, isMine, photoURL }) => {
  const [localPhotoURL, setLocalPhotoURL] = useState(photoURL || '/resources/img/avatar-default.png');
  
  useEffect(() => {
    const loadImage = async () => {
      if (photoURL) {
        try {
          console.log('Loading message photo URL:', photoURL);
          const cachedURL = await imageCache.getImage(photoURL);
          if (cachedURL) {
            console.log('Found cached message photo');
            setLocalPhotoURL(cachedURL);
          } else {
            console.log('Caching new message photo');
            setLocalPhotoURL(photoURL);
            imageCache.addImage(photoURL);
          }
        } catch (error) {
          console.error('Error loading message photo:', error);
          setLocalPhotoURL('/resources/img/avatar-default.png');
        }
      }
    };
    
    loadImage();
  }, [photoURL]);

  const renderContent = () => {
    if (message.type === 'image') {
      return (
        <div className="message-image-container">
          <img 
            src={message.content} 
            alt="Message" 
            className="message-image"
            onError={(e) => {
              console.error('Error loading message image');
              e.target.src = '/resources/img/image-error.png';
            }}
          />
        </div>
      );
    }
    return <p className="message-text">{message.content}</p>;
  };

  return (
    <div className={`message ${isMine ? 'message-mine' : 'message-other'}`}>
      {!isMine && (
        <img 
          src={localPhotoURL}
          alt="Profile" 
          className="message-avatar"
          onError={(e) => {
            console.error('Error loading avatar');
            e.target.src = '/resources/img/avatar-default.png';
          }}
        />
      )}
      <div className="message-content">
        {renderContent()}
        <span className="message-time">
          {message.timestamp?.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </span>
      </div>
    </div>
  );
};

export default function ChatScreen() {
  const { currentUser, selectedClient, setCurrentStep, setSelectedClient } = useChat();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [participantPhoto, setParticipantPhoto] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const fileInputRef = useRef(null);
  const messageCache = useRef(new Map());

  // Helper function to create consistent chat ID
  const createChatId = (user1, user2) => {
    const sortedUsers = [user1.toUpperCase(), user2.toUpperCase()].sort();
    return sortedUsers.join('_');
  };

  // Mark messages as read
  const markMessagesAsRead = async (chatId, messages) => {
    // Only mark messages as read if we are the recipient
    const unreadMessages = messages.filter(msg => 
      msg.sender.toUpperCase() === selectedClient.pseudo.toUpperCase() && 
      (!msg.readAt || new Date(msg.readAt) < msg.timestamp.toDate()) &&
      (!msg.see || msg.see !== 1)
    );

    if (unreadMessages.length > 0) {
      const batch = writeBatch(db);
      unreadMessages.forEach(msg => {
        const messageRef = doc(db, 'chats', chatId, 'messages', msg.id);
        batch.update(messageRef, { 
          readAt: serverTimestamp(),
          see: 1  // Add see=1 when recipient views the message
        });
      });
      await batch.commit();
      console.log('Marked messages as read and seen:', unreadMessages.length);
    }
  };

  useEffect(() => {
    if (!currentUser || !selectedClient) return;

    const chatId = createChatId(currentUser.pseudo, selectedClient.pseudo);
    console.log('Listening to chat ID:', chatId);

    // Try to get cached messages first
    const cachedConversation = dataCache.getConversation(chatId);
    if (cachedConversation && messageCache.current.has(chatId)) {
      const cachedMessages = messageCache.current.get(chatId);
      setMessages(cachedMessages);
      setIsInitialLoad(false);
      scrollToBottomImmediate();
    }

    const q = query(
      collection(db, 'chats', chatId, 'messages'),
      orderBy('timestamp', 'desc')
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      console.log('Received message update:', snapshot.docChanges());
      const messagesList = [];
      snapshot.forEach((doc) => {
        messagesList.push({ id: doc.id, ...doc.data() });
      });
      const sortedMessages = messagesList.reverse();
      
      // Update message cache
      messageCache.current.set(chatId, sortedMessages);
      setMessages(sortedMessages);
      
      // Mark messages as read only if we're the recipient
      if (currentUser && selectedClient) {
        await markMessagesAsRead(chatId, sortedMessages);
      }
      
      // Only smooth scroll for new messages, not initial load
      if (isInitialLoad) {
        setIsInitialLoad(false);
        scrollToBottomImmediate();
      } else {
        scrollToBottom();
      }
    }, (error) => {
      console.error('Error listening to messages:', error);
    });

    return () => {
      unsubscribe();
    };
  }, [currentUser, selectedClient]);

  useEffect(() => {
    const loadParticipantInfo = async () => {
      if (!selectedClient?.pseudo) return;

      // Try to get from cache first
      const cachedUser = dataCache.getUser(selectedClient.pseudo);
      if (cachedUser) {
        setParticipantPhoto(cachedUser.photoURL);
        return;
      }

      // If not in cache, fetch from Firestore
      try {
        const usersRef = collection(db, 'utilisateurs');
        const q = query(usersRef, query('pseudo', '==', selectedClient.pseudo.toUpperCase()));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setParticipantPhoto(userData.photoURL);
          // Cache the user data
          dataCache.setUser(selectedClient.pseudo, userData);
        }
      } catch (error) {
        console.error('Error loading participant info:', error);
      }
    };

    loadParticipantInfo();
  }, [selectedClient?.pseudo]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToBottomImmediate = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() && !isUploading) return;

    const chatId = createChatId(currentUser.pseudo, selectedClient.pseudo);
    console.log('Sending message to chat ID:', chatId);

    try {
      // Create or update chat document
      const chatRef = doc(db, 'chats', chatId);
      await setDoc(chatRef, {
        participants: [currentUser.pseudo.toUpperCase(), selectedClient.pseudo.toUpperCase()],
        lastUpdate: serverTimestamp()
      }, { merge: true });

      // Add message
      const messageRef = await addDoc(collection(db, 'chats', chatId, 'messages'), {
        content: newMessage.trim(),
        sender: currentUser.pseudo.toUpperCase(),
        type: 'text',
        timestamp: serverTimestamp(),
        see: 0  // Initialize see field to 0 (unread)
      });

      // Get recipient's user data to send notification
      const usersRef = collection(db, 'utilisateurs');
      const q = query(usersRef);
      const usersSnapshot = await getDocs(q);
      let recipientData = null;
      usersSnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData.pseudo.toUpperCase() === selectedClient.pseudo.toUpperCase()) {
          recipientData = { ...userData, numero: doc.id };
        }
      });

      if (recipientData && recipientData.notificationsEnabled) {
        const notificationData = {
          title: `Nouveau message de ${currentUser.pseudo}`,
          body: newMessage.trim(),
          icon: currentUser.photoURL || '/resources/img/avatar-default.png',
          data: {
            senderPseudo: currentUser.pseudo,
            messageId: messageRef.id,
            chatId: chatId
          }
        };

        // Send FCM notification
        const { notificationService } = await import('../services/notificationService');
        await notificationService.sendFCMNotification(recipientData.numero, notificationData);
      }
      
      console.log('Message sent successfully:', messageRef.id);
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);
    const chatId = createChatId(currentUser.pseudo, selectedClient.pseudo);
    console.log('Uploading file to chat ID:', chatId);

    try {
      // Create or update chat document
      const chatRef = doc(db, 'chats', chatId);
      await setDoc(chatRef, {
        participants: [currentUser.pseudo.toUpperCase(), selectedClient.pseudo.toUpperCase()],
        lastUpdate: serverTimestamp()
      }, { merge: true });

      // Upload image
      const imageId = uuidv4();
      const storageRef = ref(storage, `chat_images/${chatId}/${imageId}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      // Add message with image
      const messageRef = await addDoc(collection(db, 'chats', chatId, 'messages'), {
        content: downloadURL,
        sender: currentUser.pseudo.toUpperCase(),
        type: 'image',
        timestamp: serverTimestamp()
      });

      console.log('Image message sent successfully:', messageRef.id);
      setIsUploading(false);
    } catch (error) {
      console.error('Error uploading image:', error);
      setIsUploading(false);
    }
  };

  return (
    <div className="screen-content">
      <div className="chat-screen">
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
          <div className="container-fluid">
            <button 
              className="navbar-toggler" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target="#navbarSupportedContent" 
              aria-controls="navbarSupportedContent" 
              aria-expanded="false" 
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <button 
                    onClick={() => {
                      setSelectedClient(null);
                      setCurrentStep('participants');
                    }} 
                    className="btn btn-link nav-link"
                    style={{ cursor: 'pointer' }}
                  >
                    Retour aux participants
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>

       

        <div className="messages-container">
          {messages.map((message) => (
            <Message
              key={message.id}
              message={message}
              isMine={message.sender === currentUser.pseudo.toUpperCase()}
              photoURL={message.sender === currentUser.pseudo.toUpperCase() 
                ? currentUser.photoURL 
                : (participantPhoto || selectedClient?.photoURL)
              }
            />
          ))}
          <div ref={messagesEndRef} />
        </div>

        <form onSubmit={handleSendMessage} className="message-input-container">
          <button 
            type="button" 
            className="attach-button"
            onClick={() => fileInputRef.current?.click()}
            disabled={isUploading}
          >
            <i className="fa fa-paperclip"></i>
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept="image/*"
            style={{ display: 'none' }}
          />
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Écrivez votre message..."
            className="message-input"
            disabled={isUploading}
          />
          <button 
            type="submit" 
            className="send-button"
            disabled={(!newMessage.trim() && !isUploading) || isUploading}
          >
            <i className="fa fa-paper-plane"></i>
          </button>
        </form>
      </div>
    </div>
  );
}
