import React, { useState, useEffect, useRef } from 'react';

const ConsoleLogger = () => {
  const [logs, setLogs] = useState([]);
  const [position, setPosition] = useState({ x: 20, y: 20 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const loggerRef = useRef(null);
  const logsEndRef = useRef(null);

  useEffect(() => {
    const originalConsole = {
      log: console.log,
      error: console.error,
      warn: console.warn,
      info: console.info
    };

    // Override console methods
    console.log = (...args) => {
      originalConsole.log.apply(console, args);
      addLog('log', args);
    };
    console.error = (...args) => {
      originalConsole.error.apply(console, args);
      addLog('error', args);
    };
    console.warn = (...args) => {
      originalConsole.warn.apply(console, args);
      addLog('warn', args);
    };
    console.info = (...args) => {
      originalConsole.info.apply(console, args);
      addLog('info', args);
    };

    // Restore original console on cleanup
    return () => {
      console.log = originalConsole.log;
      console.error = originalConsole.error;
      console.warn = originalConsole.warn;
      console.info = originalConsole.info;
    };
  }, []);

  useEffect(() => {
    logsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [logs]);

  const addLog = (type, args) => {
    const timestamp = new Date().toLocaleTimeString();
    const message = args.map(arg => {
      if (typeof arg === 'object') {
        return JSON.stringify(arg, null, 2);
      }
      return String(arg);
    }).join(' ');

    setLogs(prevLogs => [...prevLogs, { type, message, timestamp }]);
  };

  const handleMouseDown = (e) => {
    if (loggerRef.current && e.target === loggerRef.current.firstChild) {
      setIsDragging(true);
      const rect = loggerRef.current.getBoundingClientRect();
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragOffset.x,
        y: e.clientY - dragOffset.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const clearLogs = () => {
    setLogs([]);
  };

  return (
    <div
      ref={loggerRef}
      style={{
        position: 'fixed',
        left: position.x,
        top: position.y,
        zIndex: 9999,
        width: '300px',
        backgroundColor: '#1a1a1a',
        border: '1px solid #333',
        borderRadius: '4px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
        opacity: 0.9,
        fontFamily: 'monospace',
        fontSize: '12px'
      }}
    >
      <div
        style={{
          padding: '8px',
          backgroundColor: '#333',
          color: '#fff',
          cursor: 'move',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          userSelect: 'none',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        onMouseDown={handleMouseDown}
      >
        <span>Console ({logs.length})</span>
        <button
          onClick={clearLogs}
          style={{
            background: 'none',
            border: 'none',
            color: '#fff',
            cursor: 'pointer',
            padding: '2px 6px',
            fontSize: '12px'
          }}
        >
          Clear
        </button>
      </div>
      <div
        style={{
          maxHeight: '300px',
          overflowY: 'auto',
          padding: '8px',
          backgroundColor: '#1a1a1a'
        }}
      >
        {logs.map((log, index) => (
          <div
            key={index}
            style={{
              marginBottom: '4px',
              color: log.type === 'error' ? '#ff6b6b' :
                     log.type === 'warn' ? '#ffd93d' :
                     log.type === 'info' ? '#4dabf7' : '#fff',
              wordBreak: 'break-word'
            }}
          >
            <span style={{ color: '#666' }}>[{log.timestamp}]</span> {log.message}
          </div>
        ))}
        <div ref={logsEndRef} />
      </div>
    </div>
  );
};

export default ConsoleLogger;
