import React from 'react';
import { ChatProvider } from './contexts/ChatContext';
import { useChat } from './contexts/ChatContext';
import HomeScreen from './screens/HomeScreen';
import CreateAccountScreen from './screens/CreateAccountScreen';
import ParticipantsScreen from './screens/ParticipantsScreen';
import ConversationsScreen from './screens/ConversationsScreen';
import ProfileScreen from './screens/ProfileScreen';
import ChatScreen from './screens/ChatScreen';
import CleanupScreen from './screens/CleanupScreen';
import TVDisplayScreen from './screens/TVDisplayScreen';
import NavBar from './components/NavBar';
import MessageNotification from './components/MessageNotification';
import UserNotification from './components/UserNotification';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './services/firebase'; // Initialise Firebase
import FirebaseNotificationTest from './screens/notifTest';

function AppContent() {
  const { currentStep, notifications, removeNotification } = useChat();
  const path = window.location.pathname;

  // Route spéciale pour le nettoyage
  if (path === '/cleanup') {
    return <CleanupScreen />;
  }

  // Route for TV Display
  if (path === '/tv-display') {
    return <TVDisplayScreen />;
  }

  // Déterminer le contenu principal
  let mainContent;
  const showNavBar = ['participants', 'conversations', 'profile', 'chat'].includes(currentStep);

  switch (currentStep) {
    case 'home':
      mainContent = <HomeScreen />;
      break;
    case 'civilite':
      mainContent = <CreateAccountScreen />;
      break;
    case 'participants':
      mainContent = <ParticipantsScreen />;
      break;
    case 'conversations':
      mainContent = <ConversationsScreen />;
      break;
    case 'profile':
      mainContent = <ProfileScreen />;
      break;
    case 'chat':
      mainContent = <ChatScreen />;
      break;
    case 'notifTest':
      mainContent = <FirebaseNotificationTest />;
      break;
    default:
      mainContent = <HomeScreen />;
  }

  return (
    <div className="app-wrapper">
      <div className="app-container">
        {mainContent}
        {showNavBar && <NavBar />}
        <div className="notifications-container">
          {notifications.map((notification) => 
            notification.type === 'message' ? (
              <MessageNotification
                key={notification.id}
                notification={notification}
                onClose={() => removeNotification(notification.id)}
              />
            ) : (
              <UserNotification
                key={notification.id}
                user={notification.user}
                onClose={() => removeNotification(notification.id)}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <ChatProvider>
      <AppContent />
      <ToastContainer />
    </ChatProvider>
  );
}

export default App;
