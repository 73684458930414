// Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyAXffTkHRd5pZGrakFzSYFfT61ht_fR4-I",
  authDomain: "lajava-d31d2.firebaseapp.com",
  databaseURL: "https://lajava-d31d2-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "lajava-d31d2",
  storageBucket: "lajava-d31d2.appspot.com",
  messagingSenderId: "757774678713",
  appId: "1:757774678713:web:b2de0fdf7acf2d26397968"
};

// FCM configuration
export const fcmConfig = {
  vapidKey: "BOrgAwXARUJyfhxycoHSpGWanrmE8llXaUdSqhZaxFaRTHer5Gh6_8MGWyt4Gxn1boLNy0wskLshYRXCSntxRz4",
  serverKey: "AAAAsG7j-rk:APA91bGgEMFyq5RbEP6wsvIYfX6HmDwIGAtdCrPn3QDuTTdNmKLbH258LbNdbA42CIlegaGYN56Ol_RW5v0FCpw7GGCDce2s5Lv7cY4vTFMo1rlESiZHN5uEUp0vDP_H5SM2uOSzomVa",
  projectId: "lajava-d31d2"
  };
