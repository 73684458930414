import React from 'react';
import { useChat } from '../contexts/ChatContext';
import './NavBar.css';

export default function NavBar() {
  const { currentStep, setCurrentStep, isNewMessage, setIsNewMessage } = useChat();

  const handleMessagesClick = () => {
    setIsNewMessage(false);
    setCurrentStep('conversations');
  };

  return (
    <nav className="navbar">
      <button 
        className={`nav-link ${currentStep === 'participants' ? 'active' : ''}`}
        onClick={() => setCurrentStep('participants')}
      >
        <i className="mdi mdi-account-multiple-outline"></i>
        <span>Participants</span>
      </button>
      
      <button 
        className={`nav-link ${currentStep === 'conversations' ? 'active' : ''}`}
        onClick={handleMessagesClick}
      >
        <i className="mdi mdi-message-processing-outline"></i>
        {isNewMessage && <div className="notification-dot"></div>}
        <span>Messages</span>
      </button>
      
      <button 
        className={`nav-link ${currentStep === 'profile' ? 'active' : ''}`}
        onClick={() => setCurrentStep('profile')}
      >
        <i className="mdi mdi-account-outline"></i>
        <span>Profil</span>
      </button>
    </nav>
  );
}
