import React, { useState, useEffect, useCallback } from 'react';
import { imageCache } from '../services/imageCache';
import './UserNotification.css';

const getObjectifEmoji = (objectif) => {
  switch (objectif?.toLowerCase()) {
    case 'amour':
      return '❤️';
    case 'amitié':
      return '🤝';
    case 'rencontre':
      return '👋';
    case 'sport':
      return '🏃';
    case 'culture':
      return '🎭';
    case 'professionnel':
      return '💼';
    default:
      return '👋';
  }
};

const UserNotification = ({ user, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [photoURL, setPhotoURL] = useState('/resources/img/avatar-default.png');

  const handleClose = useCallback(() => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, 150);
  }, [onClose]);

  useEffect(() => {
    const loadImage = async () => {
      if (user?.photoURL) {
        try {
          const cachedURL = await imageCache.getImage(user.photoURL);
          if (cachedURL) {
            setPhotoURL(cachedURL);
          } else {
            setPhotoURL(user.photoURL);
            await imageCache.addImage(user.photoURL);
          }
        } catch (error) {
          console.error('Error loading user photo:', error);
          setPhotoURL('/resources/img/avatar-default.png');
        }
      }
    };
    
    loadImage();
  }, [user?.photoURL]);

  useEffect(() => {
    const timer = setTimeout(handleClose, 5000);
    return () => clearTimeout(timer);
  }, [handleClose]);

  const objectifEmoji = getObjectifEmoji(user.objectif);

  return (
    <div className={`user-notification ${isVisible ? 'show' : 'hide'}`}>
      <img
        src={photoURL}
        alt={user.pseudo}
        className="notification-avatar"
        onError={() => setPhotoURL('/resources/img/avatar-default.png')}
      />
      <div className="notification-content">
        <div className="notification-header">
          <span className="notification-title">Nouveau participant</span>
          <span className="notification-emoji">👋</span>
        </div>
        <div className="notification-user-info">
          <span className="notification-pseudo">{user.pseudo}</span>
          <div className="notification-objectif">
            <span className="objectif-text">{user.objectif}</span>
            <span className="objectif-emoji">{objectifEmoji}</span>
          </div>
        </div>
      </div>
      <button 
        className="notification-close" 
        onClick={handleClose}
        aria-label="Close notification"
      >
        ×
      </button>
    </div>
  );
};

export default UserNotification;
