import React, { useState, useEffect } from 'react';
import './TVDisplayScreen.css';
import { db } from '../services/firebase';
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
  getDocs,
  where,
  serverTimestamp
} from 'firebase/firestore';

const TVDisplayScreen = () => {
  const [messages, setMessages] = useState([]);
  const [stats, setStats] = useState({
    totalMessages: 0,
    maleStats: [],
    femaleStats: []
  });

  // Verify Firebase connection
  useEffect(() => {
    console.log('Initializing TVDisplayScreen...');
    console.log('Firebase db object:', db);
    
    // Test Firebase connection
    const testCollection = async () => {
      try {
        console.log('Testing Firebase connection...');
        const testQuery = query(collection(db, 'messages'), limit(1));
        const testSnapshot = await getDocs(testQuery);
        console.log('Firebase connection successful, found documents:', !testSnapshot.empty);
        if (!testSnapshot.empty) {
          const doc = testSnapshot.docs[0];
          console.log('Sample document data:', doc.data());
        }
      } catch (error) {
        console.error('Error connecting to Firebase:', error);
      }
    };
    
    testCollection();
  }, []);

  // Update stats every minute
  useEffect(() => {
    console.log('Setting up stats listener...');
    const fetchStats = async () => {
      try {
        console.log('Fetching stats...');
        // Get messages from messages collection
        const messagesRef = collection(db, 'messages');
        const messagesQuery = query(messagesRef);
        const messagesSnapshot = await getDocs(messagesQuery);
        console.log('Found messages:', messagesSnapshot.size);
        
        // Create a map to count messages per user
        const userMessageCounts = new Map();
        
        // First get all users to get their gender
        const usersRef = collection(db, 'utilisateurs');
        const usersSnapshot = await getDocs(usersRef);
        const userGenders = new Map();
        console.log('Found users:', usersSnapshot.size);
        
        usersSnapshot.docs.forEach(doc => {
          const userData = doc.data();
          console.log('User data:', userData);
          if (userData.pseudo && userData.civilite) {
            userGenders.set(userData.pseudo, userData.civilite);
          }
        });
        
        // Count messages for each user
        messagesSnapshot.docs.forEach(doc => {
          const data = doc.data();
          console.log('Message data:', data);
          const sender = data.sender || data.pseudo || data.from;
          if (sender) {
            const count = (userMessageCounts.get(sender) || 0) + 1;
            userMessageCounts.set(sender, count);
          }
        });

        // Convert to arrays and sort
        const userStats = Array.from(userMessageCounts.entries()).map(([pseudo, count]) => ({
          pseudo,
          count,
          gender: userGenders.get(pseudo) || 'unknown'
        }));

        const maleStats = userStats
          .filter(user => user.gender === 'H')
          .sort((a, b) => b.count - a.count)
          .slice(0, 5);

        const femaleStats = userStats
          .filter(user => user.gender === 'F')
          .sort((a, b) => b.count - a.count)
          .slice(0, 5);

        console.log('Stats calculated:', { maleStats, femaleStats });
        setStats({
          totalMessages: messagesSnapshot.size,
          maleStats,
          femaleStats
        });

      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
    const interval = setInterval(fetchStats, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  // Listen to real-time messages
  useEffect(() => {
    console.log('Setting up messages listener...');
    try {
      const messagesRef = collection(db, 'messages');
      console.log('Messages collection ref:', messagesRef);
      
      const messagesQuery = query(
        messagesRef,
        orderBy('timestamp', 'desc'),
        limit(10)
      );
      console.log('Messages query:', messagesQuery);

      console.log('Setting up snapshot listener...');
      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        console.log('Snapshot received, docs:', snapshot.size);
        try {
          const newMessages = [];
          snapshot.docs.forEach(doc => {
            const data = doc.data();
            console.log('Raw message data:', data);
            
            // Try to get the message content
            let content = '';
            if (data.msg) content = data.msg;
            else if (data.message) content = data.message;
            else if (data.content) content = data.content;
            else if (data.texte) content = data.texte;
            
            // Try to get the sender
            let sender = '';
            if (data.sender) sender = data.sender;
            else if (data.pseudo) sender = data.pseudo;
            else if (data.from) sender = data.from;
            
            const message = {
              id: doc.id,
              content: content || '',
              timestamp: data.timestamp || data.date || new Date(),
              sender: sender || 'Anonymous'
            };
            
            console.log('Processed message:', message);
            newMessages.push(message);
          });

          console.log('Setting messages state with:', newMessages);
          setMessages(newMessages);
        } catch (error) {
          console.error('Error processing messages:', error);
        }
      }, (error) => {
        console.error('Error in messages listener:', error);
      });

      return () => {
        console.log('Cleaning up messages listener...');
        unsubscribe();
      };
    } catch (error) {
      console.error('Error setting up messages listener:', error);
    }
  }, []);

  // Format timestamp
  const formatTime = (timestamp) => {
    if (!timestamp) return '';
    try {
      if (timestamp instanceof Date) {
        return timestamp.toLocaleTimeString('fr-FR', {
          hour: '2-digit',
          minute: '2-digit'
        });
      }
      return new Date(timestamp.toDate()).toLocaleTimeString('fr-FR', {
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (e) {
      console.error('Error formatting timestamp:', timestamp, e);
      return '';
    }
  };

  // Debug render
  console.log('Rendering with messages:', messages);
  console.log('Rendering with stats:', stats);

  return (
    <div className="tv-display">
      <div className="header">
        <h1>Live Chat Interactions</h1>
        <div className="stats-summary">
          Total Messages: {stats.totalMessages}
        </div>
      </div>

      <div className="content">
        <div className="top-users-section">
          <div className="top-users-male">
            <h2>Top Messagers 👨</h2>
            <div className="users-list">
              {stats.maleStats.length > 0 ? (
                stats.maleStats.map((user, index) => (
                  <div key={user.pseudo} className="user-card">
                    <div className="rank">#{index + 1}</div>
                    <div className="user-info">
                      <div className="username">{user.pseudo}</div>
                      <div className="message-count">{user.count} messages</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-data">Chargement des données...</div>
              )}
            </div>
          </div>

          <div className="top-users-female">
            <h2>Top Messagers 👩</h2>
            <div className="users-list">
              {stats.femaleStats.length > 0 ? (
                stats.femaleStats.map((user, index) => (
                  <div key={user.pseudo} className="user-card">
                    <div className="rank">#{index + 1}</div>
                    <div className="user-info">
                      <div className="username">{user.pseudo}</div>
                      <div className="message-count">{user.count} messages</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-data">Chargement des données...</div>
              )}
            </div>
          </div>
        </div>

        <div className="messages-section">
          <h2>Messages en direct 💬</h2>
          <div className="messages-container">
            {Array.isArray(messages) && messages.length > 0 ? (
              messages.map(message => (
                <div key={message.id} className="message-card">
                  <div className="message-content">{message.content}</div>
                  <div className="message-meta">
                    <span className="message-sender">{message.sender}</span>
                    <span className="message-time">{formatTime(message.timestamp)}</span>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data">Chargement des messages...</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TVDisplayScreen;
