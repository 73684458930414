import React, { useState, useEffect } from 'react';
import { useChat } from '../contexts/ChatContext';
import { browserNotificationService } from '../services/browserNotificationService';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import firebaseApp, { db, storage } from '../services/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { imageCache } from '../services/imageCache';
import { toast } from 'react-toastify';
import './ProfileScreen.css';
import ConsoleLogger from '../components/ConsoleLogger';

const ProfileScreen = () => {
  const { currentUser, setCurrentUser, setCurrentStep } = useChat();
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({ ...currentUser });
  const [photoURL, setPhotoURL] = useState(currentUser.photoURL || '/resources/img/avatar-default.png');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(null);

  const loadImage = React.useCallback(async () => {
    if (!currentUser.photoURL) return;
    
    try {
      const cachedURL = await imageCache.getImage(currentUser.photoURL);
      if (cachedURL) {
        setPhotoURL(cachedURL);
      }
    } catch (error) {
      console.error('Error loading profile image:', error);
      setPhotoURL('/resources/img/avatar-default.png');
    }
  }, [currentUser.photoURL]);

  React.useEffect(() => {
    loadImage();
  }, [loadImage]);


  const handleNotificationToggle = async () => {
    if (!currentUser?.numero) {
      showErrorMessage('Utilisateur non connecté');
      return;
    }
  
    try {
      setIsLoading(true);
      
      if (!notificationsEnabled) {
        // Demander la permission explicitement
        const permission = await Notification.requestPermission();
        
        if (permission === 'granted') {
          // Initialiser Firebase Messaging seulement après avoir la permission
          await browserNotificationService.initializeForUser(firebaseApp, currentUser.numero);
          const success = await browserNotificationService.enableNotifications(currentUser.numero);
          
          if (success) {
            setNotificationsEnabled(true);
            setNotificationPermission(true);
            showSuccessMessage('Notifications activées avec succès');
          } else {
            showErrorMessage('Erreur lors de l\'activation des notifications');
          }
        } else {
          setNotificationPermission(false);
          showErrorMessage('Permission de notification refusée');
        }
      } else {
        await browserNotificationService.disableNotifications(currentUser.numero);
        setNotificationsEnabled(false);
        showSuccessMessage('Notifications désactivées');
      }
    } catch (error) {
      console.error('Error toggling notifications:', error);
      showErrorMessage('Une erreur est survenue lors de la modification des notifications');
    } finally {
      setIsLoading(false);
    }
  };

  
  React.useEffect(() => {
    const initializeNotifications = async () => {
      try {
        if (currentUser?.numero) {
          console.log('ProfileScreen: Initializing notifications for user:', currentUser.numero);
          await browserNotificationService.initializeForUser(firebaseApp, currentUser.numero);
          const permission = await browserNotificationService.checkPermission();
          setNotificationPermission(permission);
          
          // Check if notifications are enabled in Firestore
          const userDoc = await getDoc(doc(db, 'utilisateurs', currentUser.numero));
          const userData = userDoc.data();
          setNotificationsEnabled(userData?.notificationsEnabled || false);
        } else {
          console.log('ProfileScreen: No user numero available:', currentUser);
        }
      } catch (error) {
        console.error('Error initializing notifications:', error);
      }
    };

    if (currentUser?.numero) {
      initializeNotifications();
    } else {
      console.log('ProfileScreen: No user or no numero:', currentUser);
    }
  }, [currentUser?.numero]);

  React.useEffect(() => {
    const checkNotificationStatus = async () => {
      const permission = await browserNotificationService.checkPermission();
      setNotificationPermission(permission);
    };
    checkNotificationStatus();
  }, []);

  const handlePhotoChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsLoading(true);
    setError(null);

    try {
      const imageRef = ref(storage, `avatars/${currentUser.numero}/${uuidv4()}`);
      await uploadBytes(imageRef, file);
      const downloadURL = await getDownloadURL(imageRef);
      
      await updateDoc(doc(db, 'utilisateurs', currentUser.numero), {
        photoURL: downloadURL
      });

      setCurrentUser({ ...currentUser, photoURL: downloadURL });
      setPhotoURL(downloadURL);
      await imageCache.addImage(downloadURL);
    } catch (error) {
      console.error('Error uploading photo:', error);
      setError('Erreur lors du téléchargement de la photo');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    setError(null);

    try {
      await updateDoc(doc(db, 'utilisateurs', currentUser.numero), {
        description: editedUser.description,
        objectif: editedUser.objectif,
        age: editedUser.age
      });

      setCurrentUser({ ...currentUser, ...editedUser });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Erreur lors de la mise à jour du profil');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTestNotification = async () => {
    if (!currentUser?.numero) {
      showErrorMessage('Utilisateur non connecté');
      return;
    }

    try {
      // Make sure notifications are initialized
      await browserNotificationService.initializeForUser(firebaseApp, currentUser.numero);
      await browserNotificationService.showNotification(
        'Test de notification',
        {
          body: 'Si vous voyez ceci, les notifications fonctionnent correctement!',
          onClick: () => {
            console.log('Notification clicked');
          }
        }
      );

      // Second test: Firebase notification
      const userDoc = await getDoc(doc(db, 'utilisateurs', currentUser.numero));
      const userData = userDoc.data();
      
      if (userData?.fcmToken) {
        showSuccessMessage('Token FCM trouvé! Vérifiez la console Firebase pour envoyer un message test.');
        console.log('FCM Token pour tester dans Firebase Console:', userData.fcmToken);
      } else {
        showErrorMessage('Aucun token FCM trouvé. Assurez-vous que les notifications sont activées.');
      }
    } catch (error) {
      console.error('Error testing notification:', error);
      showErrorMessage('Erreur lors du test de notification');
    }
  };

  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const showErrorMessage = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  React.useEffect(() => {
    const loadNotificationStatus = async () => {
      if (currentUser.numero) {
        try {
          const userDoc = await getDoc(doc(db, 'utilisateurs', currentUser.numero));
          const userData = userDoc.data();
          setNotificationsEnabled(userData?.notificationsEnabled || false);
        } catch (error) {
          console.error('Error loading notification status:', error);
        }
      }
    };
    
    loadNotificationStatus();
  }, [currentUser.numero]);

  if (error) {
    return (
      <div className="profile-screen">
        <div className="error-message">
          {error}
          <button onClick={() => setError(null)}>Réessayer</button>
        </div>
      </div>
    );
  }

  return (
    <div className="profile-screen">
      <ConsoleLogger />
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner">Chargement...</div>
        </div>
      )}
      
      <div className="profile-content">
        <div className="profile-header">
          <div className="profile-photo-container">
            <img 
              src={photoURL} 
              alt={currentUser.numero} 
              className="profile-photo"
              onError={(e) => {
                e.target.src = '/resources/img/avatar-default.png';
              }}
            />
            <label className="photo-upload-button" disabled={isLoading}>
              <i className="fas fa-camera"></i>
              <input 
                type="file" 
                accept="image/*"
                onChange={handlePhotoChange}
                style={{ display: 'none' }}
                disabled={isLoading}
              />
            </label>
          </div>
          <h2>{currentUser.numero}</h2>
        </div>

        <div className="profile-details">
          {isEditing ? (
            <>
              <div className="form-group">
                <label>Âge</label>
                <select 
                  value={editedUser.age}
                  onChange={(e) => setEditedUser({ ...editedUser, age: e.target.value })}
                  disabled={isLoading}
                >
                  <option value="18-25">18-25 ans</option>
                  <option value="25-35">25-35 ans</option>
                  <option value="35-45">35-45 ans</option>
                  <option value="45+">45+ ans</option>
                </select>
              </div>

              <div className="form-group">
                <label>Description</label>
                <textarea
                  value={editedUser.description}
                  onChange={(e) => setEditedUser({ ...editedUser, description: e.target.value })}
                  placeholder="Parlez-nous de vous..."
                  disabled={isLoading}
                />
              </div>

              <div className="form-group">
                <label>Objectif</label>
                <select 
                  value={editedUser.objectif}
                  onChange={(e) => setEditedUser({ ...editedUser, objectif: e.target.value })}
                  disabled={isLoading}
                >
                  <option value="Amour">Amour</option>
                  <option value="Amitié">Amitié</option>
                  <option value="Discussion">Discussion</option>
                </select>
              </div>

              <div className="button-group">
                <button 
                  className="cancel-button"
                  onClick={() => {
                    setEditedUser({ ...currentUser });
                    setIsEditing(false);
                  }}
                  disabled={isLoading}
                >
                  Annuler
                </button>
                <button 
                  className="save-button"
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  Enregistrer
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="info-group">
                <label>Âge</label>
                <p>{currentUser.age}</p>
              </div>

              <div className="info-group">
                <label>Description</label>
                <p>{currentUser.description || 'Aucune description'}</p>
              </div>

              <div className="info-group">
                <label>Objectif</label>
                <p>
                  {currentUser.objectif === 'Amour' && 'Amour'}
                  {currentUser.objectif === 'Amitié' && 'Amitié'}
                  {currentUser.objectif === 'Discussion' && 'Discussion'}
                </p>
              </div>

              <div className="notification-group">
                <label>Notifications du navigateur</label>
                <div className="notification-toggle">
                  <button 
                    onClick={handleNotificationToggle}
                    className={`toggle-button ${notificationsEnabled ? 'enabled' : ''}`}
                    disabled={notificationPermission === false}
                  >
                    {notificationsEnabled ? 'Activées' : 'Désactivées'}
                  </button>
                  {notificationPermission === false && (
                    <div className="notification-warning">
                      <p>Les notifications sont bloquées par votre navigateur.</p>
                      <button 
                        onClick={async () => {
                          const permission = await browserNotificationService.requestPermission();
                          setNotificationPermission(permission);
                          if (permission) {
                            showSuccessMessage('Notifications autorisées avec succès !');
                          }
                        }}
                        className="request-permission-button"
                      >
                        Autoriser les notifications
                      </button>
                    </div>
                  )}
                  {notificationsEnabled && (
                    <button 
                      onClick={handleTestNotification}
                      className="test-notification-button"
                    >
                      Tester la notification
                    </button>
                  )}
                </div>
              </div>

              <button 
                className="edit-button"
                onClick={() => setIsEditing(true)}
                disabled={isLoading}
              >
                Modifier le profil
              </button>
              
              <div className="notification-test-link">
                <button 
                  className="secondary-button"
                  onClick={() => setCurrentStep('notifTest')}
                  style={{ marginTop: '20px' }}
                >
                  Advanced Notification Test
                </button>
              </div>

              <button 
                className="logout-button"
                onClick={() => {
                  localStorage.removeItem('chatState');
                  setCurrentUser(null);
                  setCurrentStep('home');
                }}
                disabled={isLoading}
              >
                Se déconnecter
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileScreen;
