import React, { useEffect, useState } from 'react';
import { useChat } from '../contexts/ChatContext';
import './ConversationsScreen.css';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import { db } from '../services/firebase';
import { dataCache } from '../services/dataCache';

export default function ConversationsScreen() {
  const { currentUser, setCurrentStep, setSelectedClient } = useChat();
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    setError(null);

    const loadConversations = async () => {
      if (!currentUser?.pseudo) {
        setIsLoading(false);
        return;
      }

      try {
        const currentUserUpper = currentUser.pseudo.toUpperCase();
        
        // Get all chats
        const chatsRef = collection(db, 'chats');
        const chatsSnapshot = await getDocs(chatsRef);
        
        // Get all users
        const usersRef = collection(db, 'utilisateurs');
        const usersSnapshot = await getDocs(usersRef);
        const usersMap = new Map();
        usersSnapshot.forEach(doc => {
          const userData = doc.data();
          usersMap.set(userData.pseudo.toLowerCase(), userData);
        });

        // Cache the users data
        dataCache.setUsers(usersMap);

        const conversationsData = [];
        
        // Process each chat
        for (const chatDoc of chatsSnapshot.docs) {
          if (!isMounted) return;
          
          const chatId = chatDoc.id;
          if (!chatId.includes(currentUserUpper)) continue;
          
          // Get messages
          const messagesRef = collection(db, 'chats', chatId, 'messages');
          const messagesQuery = query(messagesRef, orderBy('timestamp', 'desc'));
          
          try {
            const messagesSnapshot = await getDocs(messagesQuery);
            if (messagesSnapshot.empty) continue;
            
            const messages = messagesSnapshot.docs.map(doc => doc.data());
            const lastMessage = messages[0];
            const otherParticipantPseudo = chatId.split('_')
              .find(p => p !== currentUserUpper)
              ?.toLowerCase();

            if (otherParticipantPseudo) {
              const otherUser = usersMap.get(otherParticipantPseudo);
              
              // Check for unread messages from the other participant
              const hasUnreadMessages = messages.some(msg => 
                msg.sender.toUpperCase() === otherParticipantPseudo.toUpperCase() && 
                (!msg.see || msg.see === 0)
              );

              if (hasUnreadMessages) {
                console.log('Found unread messages from:', otherParticipantPseudo);
              }

              const conversation = {
                id: chatId,
                otherParticipant: {
                  pseudo: otherParticipantPseudo,
                  photoURL: otherUser?.photoURL || null
                },
                lastMessage: {
                  text: lastMessage.content,
                  timestamp: lastMessage.timestamp
                },
                hasUnreadMessages
              };
              
              conversationsData.push(conversation);
            }
          } catch (error) {
            console.error('Error processing chat:', chatId, error);
          }
        }

        if (!isMounted) return;

        // Sort conversations by timestamp
        conversationsData.sort((a, b) => {
          const timestampA = a.lastMessage?.timestamp?.toDate() || new Date(0);
          const timestampB = b.lastMessage?.timestamp?.toDate() || new Date(0);
          return timestampB - timestampA;
        });

        setConversations(conversationsData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error loading conversations:', error);
        if (isMounted) {
          setError('Erreur lors du chargement des conversations');
          setIsLoading(false);
        }
      }
    };

    loadConversations();

    return () => {
      isMounted = false;
    };
  }, [currentUser?.pseudo]);

  const handleConversationClick = (conversation) => {
    setSelectedClient({
      pseudo: conversation.otherParticipant.pseudo
    });
    setCurrentStep('chat');
  };

  const formatLastMessageTime = (date) => {
    if (!date) return '';
    
    const now = new Date();
    const diff = now - date;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    
    if (days === 0) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else if (days === 1) {
      return 'Hier';
    } else if (days < 7) {
      return date.toLocaleDateString([], { weekday: 'long' });
    } else {
      return date.toLocaleDateString([], { day: 'numeric', month: 'short' });
    }
  };

  if (error) {
    return (
      <div className="conversations-screen">
        <div className="error-message">
          {error}
          <button onClick={() => window.location.reload()}>Réessayer</button>
        </div>
      </div>
    );
  }

  return (
    <div className="conversations-screen">
      {isLoading ? (
        <div className="loading-spinner">Chargement des conversations...</div>
      ) : (
        <div className="conversations-list">
          {conversations.map(conversation => (
            <div
              key={conversation.id}
              className="conversation-item"
              onClick={() => handleConversationClick(conversation)}
            >
              <div className="conversation-avatar-container">
                <img
                  src={conversation.otherParticipant.photoURL || '/resources/img/avatar-default.png'}
                  alt={conversation.otherParticipant.pseudo}
                  className="conversation-avatar"
                  onError={(e) => {
                    e.target.src = '/resources/img/avatar-default.png';
                  }}
                />
                {conversation.hasUnreadMessages && <div className="notification-dot"></div>}
              </div>
              
              <div className="conversation-content">
                <div className="conversation-header">
                  <span className="conversation-name">
                    {conversation.otherParticipant.pseudo}
                  </span>
                  <span className="conversation-time">
                    {formatLastMessageTime(conversation.lastMessage.timestamp?.toDate())}
                  </span>
                </div>
                
                <div className="conversation-message">
                  <span className="message-preview">
                    {conversation.lastMessage?.text || 'Nouvelle conversation'}
                  </span>
                </div>
              </div>
            </div>
          ))}
          
          {conversations.length === 0 && (
            <div className="no-conversations">
              <p>Aucune conversation</p>
              <button 
                className="start-chat-btn"
                onClick={() => setCurrentStep('participants')}
              >
                Démarrer une conversation
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
