import React, { useState } from 'react';
import { 
  collection, 
  query, 
  getDocs, 
  writeBatch,
  deleteDoc,
  doc
} from 'firebase/firestore';
import { 
  ref, 
  listAll, 
  deleteObject 
} from 'firebase/storage';
import { db, storage } from '../services/firebase';
import './CleanupScreen.css';

// Mot de passe hashé (à remplacer par un vrai système d'auth)
const ADMIN_PASSWORD = "lajava2024";

export default function CleanupScreen() {
  const [status, setStatus] = useState('idle');
  const [message, setMessage] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === ADMIN_PASSWORD) {
      setIsAuthenticated(true);
      setError('');
    } else {
      setError('Mot de passe incorrect');
      setPassword('');
    }
  };

  const clearFirebase = async () => {
    try {
      setStatus('cleaning');
      setMessage('Nettoyage en cours...');

      // Supprimer les utilisateurs
      const usersQuery = query(collection(db, 'utilisateurs'));
      const usersSnapshot = await getDocs(usersQuery);
      
      const usersBatch = writeBatch(db);
      usersSnapshot.forEach((document) => {
        usersBatch.delete(document.ref);
      });
      await usersBatch.commit();
      setMessage(prev => prev + '\nUtilisateurs supprimés');

      // Supprimer les messages
      const messagesQuery = query(collection(db, 'messages'));
      const messagesSnapshot = await getDocs(messagesQuery);
      
      const messagesBatch = writeBatch(db);
      messagesSnapshot.forEach((document) => {
        messagesBatch.delete(document.ref);
      });
      await messagesBatch.commit();
      setMessage(prev => prev + '\nMessages supprimés');

      // Supprimer les chats
      const chatsQuery = query(collection(db, 'chats'));
      const chatsSnapshot = await getDocs(chatsQuery);
      
      for (const chatDoc of chatsSnapshot.docs) {
        // Supprimer tous les messages dans le chat
        const chatMessagesQuery = query(collection(db, 'chats', chatDoc.id, 'messages'));
        const chatMessagesSnapshot = await getDocs(chatMessagesQuery);
        
        const chatMessagesBatch = writeBatch(db);
        chatMessagesSnapshot.forEach((msgDoc) => {
          chatMessagesBatch.delete(msgDoc.ref);
        });
        await chatMessagesBatch.commit();
        
        // Supprimer le document du chat
        await deleteDoc(doc(db, 'chats', chatDoc.id));
      }
      setMessage(prev => prev + '\nChats supprimés');

      // Supprimer les fichiers du storage
      const imagesRef = ref(storage, 'images');
      const imagesList = await listAll(imagesRef);
      
      const deletePromises = imagesList.items.map((itemRef) => 
        deleteObject(itemRef).catch(error => {
          console.error(`Erreur lors de la suppression de ${itemRef.fullPath}:`, error);
          setMessage(prev => prev + `\nErreur: ${error.message}`);
        })
      );
      
      await Promise.all(deletePromises);
      setMessage(prev => prev + '\nImages supprimées');

      // Supprimer les photos de profil
      const profilesRef = ref(storage, 'profile_photos');
      const profilesList = await listAll(profilesRef);
      
      const deleteProfilePromises = profilesList.items.map((itemRef) => 
        deleteObject(itemRef).catch(error => {
          console.error(`Erreur lors de la suppression de ${itemRef.fullPath}:`, error);
          setMessage(prev => prev + `\nErreur: ${error.message}`);
        })
      );
      
      await Promise.all(deleteProfilePromises);
      setMessage(prev => prev + '\nPhotos de profil supprimées');

      setStatus('success');
      setMessage(prev => prev + '\n\nNettoyage terminé avec succès !');
    } catch (error) {
      console.error('Erreur lors du nettoyage:', error);
      setStatus('error');
      setMessage(`Erreur lors du nettoyage: ${error.message}`);
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="cleanup-screen">
        <div className="cleanup-container">
          <h1>Authentification Requise</h1>
          <form onSubmit={handleLogin} className="login-form">
            <div className="form-group">
              <label htmlFor="password">Mot de passe administrateur</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Entrez le mot de passe"
                className="password-input"
              />
            </div>
            {error && <div className="error-message">{error}</div>}
            <button type="submit" className="login-button">
              Se connecter
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="cleanup-screen">
      <div className="cleanup-container">
        <div className="header">
          <h1>Nettoyage Firebase</h1>
          <button 
            onClick={() => setIsAuthenticated(false)} 
            className="logout-button"
          >
            Déconnexion
          </button>
        </div>
        
        <p className="warning">⚠️ Attention: Cette action est irréversible !</p>
        
        <div className="status-container">
          <div className={`status-indicator ${status}`}>
            {status === 'idle' && 'Prêt'}
            {status === 'cleaning' && 'Nettoyage en cours...'}
            {status === 'success' && 'Terminé'}
            {status === 'error' && 'Erreur'}
          </div>
          
          {message && (
            <pre className="status-message">
              {message}
            </pre>
          )}
        </div>

        <button 
          onClick={clearFirebase} 
          disabled={status === 'cleaning'}
          className={`cleanup-button ${status}`}
        >
          {status === 'cleaning' ? 'Nettoyage en cours...' : 'Nettoyer Firebase'}
        </button>
      </div>
    </div>
  );
}
