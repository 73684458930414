import React, { useState, useEffect } from "react";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useChat } from '../contexts/ChatContext';
import './ProfileScreen.css';
import firebaseApp from '../services/firebase';
import { fcmConfig } from '../firebase-config';
import { notificationService } from '../services/notificationService';
import { browserNotificationService } from '../services/browserNotificationService';

const MAX_LOGS = 50;

const FirebaseNotificationTest = () => {
  const [message, setMessage] = useState("");
  const [token, setToken] = useState(null);
  const [manualToken, setManualToken] = useState("");
  const [useManualToken, setUseManualToken] = useState(false);
  const [notification, setNotification] = useState(null);
  const [logs, setLogs] = useState([]);
  const [notificationPermission, setNotificationPermission] = useState(null);
  const [fcmResponse, setFcmResponse] = useState(null);
  const { setCurrentStep } = useChat();

  const addLog = (type, message, details = null) => {
    const timestamp = new Date().toISOString();
    const logEntry = {
      timestamp,
      type,
      message,
      details: details ? JSON.stringify(details, null, 2) : null
    };

    setLogs(prevLogs => {
      const newLogs = [logEntry, ...prevLogs];
      return newLogs.slice(0, MAX_LOGS);
    });

    // Also log to console for debugging
    const consoleMsg = `[${type}] ${message}`;
    switch (type) {
      case 'error':
        console.error(consoleMsg, details || '');
        break;
      case 'warning':
        console.warn(consoleMsg, details || '');
        break;
      default:
        console.log(consoleMsg, details || '');
    }
  };

  const checkNotificationPermission = async () => {
    try {
      const permission = await Notification.permission;
      setNotificationPermission(permission);
      addLog('info', `Current notification permission status: ${permission}`);
      return permission;
    } catch (error) {
      addLog('error', 'Error checking notification permission', error);
      return null;
    }
  };

  const requestNotificationPermission = async () => {
    try {
      addLog('info', 'Requesting notification permission...');
      const permission = await Notification.requestPermission();
      setNotificationPermission(permission);
      addLog('info', `Notification permission status: ${permission}`);
      return permission;
    } catch (error) {
      addLog('error', 'Error requesting notification permission', error);
      return null;
    }
  };

  useEffect(() => {
    checkNotificationPermission();
    
    if (!useManualToken) {
      addLog('info', 'Initializing Firebase Messaging...');
      const messaging = getMessaging(firebaseApp);

      // Request permission and get token
      requestNotificationPermission().then(permission => {
        if (permission === 'granted') {
          addLog('info', 'Getting FCM token...');
          getToken(messaging, { vapidKey: fcmConfig.vapidKey })
            .then((currentToken) => {
              if (currentToken) {
                addLog('success', 'FCM token obtained successfully', { token: currentToken });
                setToken(currentToken);
              } else {
                addLog('error', 'No token available despite permission granted');
              }
            })
            .catch((err) => {
              addLog('error', 'Error getting FCM token', err);
            });
        } else {
          addLog('warning', `Cannot get FCM token - notification permission: ${permission}`);
        }
      });

      // Listen for incoming messages
      addLog('info', 'Setting up message listener...');
      onMessage(messaging, (payload) => {
        addLog('info', 'Notification received', payload);
        setNotification(payload.notification);
      });
    } else {
      addLog('info', 'Using manual token mode');
    }
  }, [useManualToken]);

  const handleSendMessage = async () => {
    setFcmResponse(null); // Reset previous response
    
    if (!message.trim()) {
      addLog('warning', 'Empty message, sending canceled');
      alert("Please enter a message");
      return;
    }

    const currentToken = useManualToken ? manualToken : token;

    if (!currentToken) {
      addLog('error', 'No token available');
      alert("Token not available. Either allow notifications or enter a manual token.");
      return;
    }

    const permission = await checkNotificationPermission();
    if (permission !== 'granted') {
      addLog('warning', 'Notifications not allowed by browser');
      const newPermission = await requestNotificationPermission();
      if (newPermission !== 'granted') {
        addLog('error', 'User denied notification permission');
        alert("Please allow notifications to see them. You can change this in your browser settings.");
        return;
      }
    }

    addLog('info', 'Preparing to send notification', {
      useManualToken,
      messageLength: message.length,
      tokenLength: currentToken.length,
      notificationPermission: permission
    });

    try {
      const notificationData = {
        title: "Test Notification",
        body: message,
        icon: "/resources/img/icon.png",
        data: {
          messageId: Date.now().toString(),
          content: message
        }
      };

      addLog('info', 'Sending browser notification...');
      try {
        // Show browser notification directly first
        const notification = new Notification(notificationData.title, {
          body: notificationData.body,
          icon: notificationData.icon,
          data: notificationData.data,
          tag: 'test-notification'
        });

        notification.onclick = () => {
          addLog('info', 'Browser notification clicked');
          window.focus();
        };

        addLog('success', 'Browser notification sent directly');
      } catch (error) {
        addLog('error', 'Error showing direct browser notification', error);
        
        // Fallback to browserNotificationService
        addLog('info', 'Trying fallback to browserNotificationService...');
        await browserNotificationService.showNotification(
          notificationData.title,
          {
            body: notificationData.body,
            icon: notificationData.icon,
            data: notificationData.data
          }
        );
        addLog('success', 'Browser notification sent via service');
      }

      addLog('info', 'Sending FCM notification...');
      // Send FCM notification and capture response
      const response = await notificationService.sendFCMNotification(currentToken, notificationData);
      setFcmResponse(response);
      
      if (response && response.success) {
        addLog('success', 'FCM notification sent successfully', response);
      } else {
        addLog('warning', 'FCM notification sent with issues', response);
      }
      
      setMessage("");
    } catch (error) {
      addLog('error', 'Failed to send notification', error);
      setFcmResponse({
        success: false,
        error: error.message || 'Unknown error occurred'
      });
    }
  };

  const getLogColor = (type) => {
    switch (type) {
      case 'error': return '#ffebee';
      case 'warning': return '#fff3e0';
      case 'success': return '#e8f5e9';
      default: return '#e3f2fd';
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <div  style={{backgroundColor: '#FD88A5',width: '120px', height:'15px', marginBottom: '20px'}}>
        <a href="#" className="back-button" onClick={(e) => { 
          e.preventDefault(); 
          setCurrentStep('profile'); 
        }}>
          retour
        </a>
      </div>
      <h1>Test de Notifications Firebase</h1>

      <div style={{ marginBottom: "20px" }}>
        <div style={{ marginBottom: "15px", padding: "10px", backgroundColor: getLogColor(notificationPermission === 'granted' ? 'success' : 'warning') }}>
          <strong>Notification Permission:</strong> {notificationPermission || 'unknown'}
          {notificationPermission !== 'granted' && (
            <button 
              onClick={requestNotificationPermission}
              style={{ marginLeft: "10px" }}
            >
              Request Permission
            </button>
          )}
        </div>
        <label style={{ display: "block", marginBottom: "10px" }}>
          <input
            type="checkbox"
            checked={useManualToken}
            onChange={(e) => {
              setUseManualToken(e.target.checked);
              if (!e.target.checked) {
                setManualToken("");
                addLog('info', 'Switched to automatic token mode');
              } else {
                addLog('info', 'Switched to manual token mode');
              }
            }}
            style={{ marginRight: "10px" }}
          />
          Use Manual FCM Token
        </label>

        {useManualToken && (
          <input
            type="text"
            value={manualToken}
            onChange={(e) => {
              setManualToken(e.target.value);
              addLog('info', 'Manual token updated', { length: e.target.value.length });
            }}
            placeholder="Enter FCM Token"
            style={{
              width: "100%",
              padding: "8px",
              marginBottom: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc"
            }}
          />
        )}

        {!useManualToken && token && (
          <div style={{ marginTop: "10px", wordBreak: "break-all" }}>
            <strong>Current Token:</strong> {token}
          </div>
        )}
      </div>

      <textarea
        rows="4"
        cols="50"
        placeholder="Écrivez votre message ici..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        style={{ display: "block", marginBottom: "20px", width: "100%" }}
      />
      <button onClick={handleSendMessage}>Envoyer le message</button>

      {notification && (
        <div style={{ marginTop: "20px", padding: "10px", border: "1px solid black" }}>
          <h2>Notification Reçue:</h2>
          <p><strong>Title:</strong> {notification.title}</p>
          <p><strong>Body:</strong> {notification.body}</p>
        </div>
      )}

      {fcmResponse && (
        <div style={{ 
          marginTop: "20px", 
          padding: "10px", 
          border: "1px solid black",
          backgroundColor: fcmResponse.success ? '#e8f5e9' : '#ffebee'
        }}>
          <h2>FCM API Response:</h2>
          <pre style={{ 
            margin: "10px 0",
            padding: "10px",
            backgroundColor: "rgba(0,0,0,0.05)",
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "200px"
          }}>
            {JSON.stringify(fcmResponse, null, 2)}
          </pre>
        </div>
      )}

      <div style={{ marginTop: "30px" }}>
        <h2>Logs ({logs.length}/{MAX_LOGS})</h2>
        <div style={{ 
          maxHeight: "400px", 
          overflowY: "auto", 
          border: "1px solid #ccc", 
          borderRadius: "4px",
          backgroundColor: "#f5f5f5"
        }}>
          {logs.map((log, index) => (
            <div
              key={index}
              style={{
                padding: "8px",
                borderBottom: "1px solid #ddd",
                backgroundColor: getLogColor(log.type),
                fontSize: "14px",
                fontFamily: "monospace"
              }}
            >
              <div style={{ color: "#666" }}>
                {new Date(log.timestamp).toLocaleTimeString()}
              </div>
              <div style={{ fontWeight: "bold" }}>
                [{log.type.toUpperCase()}] {log.message}
              </div>
              {log.details && (
                <pre style={{ 
                  margin: "4px 0 0 0",
                  padding: "4px",
                  backgroundColor: "rgba(255,255,255,0.5)",
                  borderRadius: "2px",
                  fontSize: "12px",
                  overflowX: "auto"
                }}>
                  {log.details}
                </pre>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FirebaseNotificationTest;
