import React, { useState } from 'react';
import { useChat } from '../contexts/ChatContext';
import { db, storage } from '../services/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { browserNotificationService } from '../services/browserNotificationService';
import './CreateAccountScreen.css';

const STEPS = {
  GENRE: 0,
  PSEUDO: 1,
  AGE: 2,
  PHOTO: 3,
  OBJECTIF: 4,
  NOTIFICATIONS: 5,
  RECAP: 6
};

const AGE_RANGES = [
  { label: 'De 18 à 25 ans', value: '18-25' },
  { label: 'De 25 à 35 ans', value: '25-35' },
  { label: 'De 35 à 45 ans', value: '35-45' },
  { label: '+ de 45 ans', value: '45+' }
];

const OBJECTIFS = [
  { 
    id: 'cerise',
    title: 'Trouver sa moitiée',
    icon: '/resources/img/icons/cerise_photo.png',
    color: '#FF4458'
  },
  {
    id: 'fraise',
    title: 'Une soirée aux plaisirs sucrés',
    icon: '/resources/img/icons/fraise_photo.png',
    color: '#FF6B81'
  },
  {
    id: 'pasteque',
    title: 'Des calins sans pépins',
    icon: '/resources/img/icons/pasteque_photo.png',
    color: '#FF8E9E'
  },
  {
    id: 'raisin',
    title: 'Un verre sans se prendre la grappe',
    icon: '/resources/img/icons/raisin_photo.png',
    color: '#FFA1B0'
  }
];

export default function CreateAccountScreen() {
  const { setCurrentStep, currentUser, setCurrentUser } = useChat();
  const [currentStepIndex, setCurrentStepIndex] = useState(STEPS.GENRE);
  const [formData, setFormData] = useState({
    numero: currentUser.numero,
    genre: '',
    pseudo: '',
    age: '',
    photo: null,
    photoURL: '',
    objectif: '',
    notificationsEnabled: false
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(null);

  const handleGenreSelect = (genre) => {
    setFormData(prev => ({ ...prev, genre }));
    nextStep();
  };

  const handlePseudoSubmit = (e) => {
    e.preventDefault();
    if (formData.pseudo.length < 2 || formData.pseudo.length > 20) {
      setError('Le pseudo doit contenir entre 2 et 20 caractères');
      return;
    }
    setError('');
    nextStep();
  };

  const handleAgeSelect = (age) => {
    setFormData(prev => ({ ...prev, age }));
    nextStep();
  };

  const handlePhotoSelect = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      setError('La photo ne doit pas dépasser 5MB');
      return;
    }

    try {
      setFormData(prev => ({ 
        ...prev, 
        photo: file,
        photoURL: URL.createObjectURL(file)
      }));
      nextStep();
    } catch (error) {
      console.error('Error with photo:', error);
      setError('Erreur lors du chargement de la photo');
    }
  };

  const uploadPhoto = async (file) => {
    const fileExtension = file.name.split('.').pop();
    const fileName = `${formData.numero}_${Date.now()}.${fileExtension}`;
    const storageRef = ref(storage, `profile_photos/${fileName}`);
    
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError('');

    try {
      // Upload de la photo
      let photoURL = '';
      if (formData.photo) {
        photoURL = await uploadPhoto(formData.photo);
      }

      // Création du document utilisateur
      const userData = {
        numero: formData.numero,
        pseudo: formData.pseudo.toUpperCase(),
        genre: formData.genre,
        age: formData.age,
        objectif: formData.objectif,
        photoURL: photoURL,
        notificationsEnabled: formData.notificationsEnabled,
        createdAt: new Date().toISOString(),
        lastActive: new Date().toISOString()
      };

      // Sauvegarde dans Firestore
      await setDoc(doc(db, 'utilisateurs', formData.numero), userData);

      // Mise à jour du contexte
      setCurrentUser(userData);
      
      // Sauvegarde dans localStorage
      localStorage.setItem('chatState', JSON.stringify({
        currentUser: userData,
        currentStep: 'participants'
      }));

      // Redirection
      setCurrentStep('participants');
    } catch (error) {
      console.error('Error creating account:', error);
      setError('Erreur lors de la création du compte. Veuillez réessayer.');
      setLoading(false);
    }
  };

  const handleObjectifSelect = (objectif) => {
    setFormData(prev => ({ ...prev, objectif }));
    nextStep();
  };

  const handleNotificationToggle = async () => {
    try {
      const enabled = await browserNotificationService.enableNotifications();
      setFormData(prev => ({ ...prev, notificationsEnabled: enabled }));
      setNotificationPermission(enabled);
      if (enabled) {
        nextStep();
      }
    } catch (error) {
      console.error('Error enabling notifications:', error);
      setError('Erreur lors de l\'activation des notifications');
    }
  };

  const skipNotifications = () => {
    setFormData(prev => ({ ...prev, notificationsEnabled: false }));
    nextStep();
  };

  const nextStep = () => {
    setCurrentStepIndex(prev => prev + 1);
  };

  const renderStep = () => {
    switch (currentStepIndex) {
      case STEPS.GENRE:
        return (
          <div className="step-container genre-step">
            <h2>Quel est ton genre ?</h2>
            <div className="genre-buttons">
              <button 
                className={`genre-button ${formData.genre === 'H' ? 'selected' : ''}`}
                onClick={() => handleGenreSelect('H')}
              >
                <i className="fas fa-mars"></i>
                <span>Homme</span>
              </button>
              <button 
                className={`genre-button ${formData.genre === 'F' ? 'selected' : ''}`}
                onClick={() => handleGenreSelect('F')}
              >
                <i className="fas fa-venus"></i>
                <span>Femme</span>
              </button>
            </div>
          </div>
        );

      case STEPS.PSEUDO:
        return (
          <div className="step-container pseudo-step">
            <h2>Choisis ton pseudo</h2>
            <form onSubmit={handlePseudoSubmit}>
              <input
                type="text"
                value={formData.pseudo}
                onChange={e => setFormData(prev => ({ ...prev, pseudo: e.target.value }))}
                placeholder="Ton pseudo"
                maxLength={20}
                className="input-field"
              />
              <button type="submit" className="next-button">Suivant</button>
            </form>
            {error && <div className="error-message">{error}</div>}
          </div>
        );

      case STEPS.AGE:
        return (
          <div className="step-container age-step">
            <h2>Quel âge as-tu ?</h2>
            <div className="age-options">
              {AGE_RANGES.map(range => (
                <button
                  key={range.value}
                  className={`age-button ${formData.age === range.value ? 'selected' : ''}`}
                  onClick={() => handleAgeSelect(range.value)}
                >
                  {range.label}
                </button>
              ))}
            </div>
          </div>
        );

      case STEPS.PHOTO:
        return (
          <div className="step-container photo-step">
            <h2>Ajoute ta plus belle photo</h2>
            <div className="photo-upload-container">
              {formData.photoURL ? (
                <div className="preview-container">
                  <img src={formData.photoURL} alt="Preview" className="photo-preview" />
                  <button 
                    className="change-photo-button"
                    onClick={() => document.getElementById('photo-input').click()}
                  >
                    Changer
                  </button>
                </div>
              ) : (
                <div className="upload-placeholder" onClick={() => document.getElementById('photo-input').click()}>
                  <i className="fas fa-camera"></i>
                  <span>Cliquer pour ajouter</span>
                </div>
              )}
              <input
                id="photo-input"
                type="file"
                accept="image/*"
                onChange={handlePhotoSelect}
                style={{ display: 'none' }}
              />
            </div>
            {error && <div className="error-message">{error}</div>}
          </div>
        );

      case STEPS.OBJECTIF:
        return (
          <div className="step-container objectif-step">
            <h2>Pourquoi es-tu là ce soir ?</h2>
            <div className="objectifs-grid">
              {OBJECTIFS.map(obj => (
                <button
                  key={obj.id}
                  className={`objectif-card ${formData.objectif === obj.id ? 'selected' : ''}`}
                  onClick={() => handleObjectifSelect(obj.id)}
                  style={{ '--card-color': obj.color }}
                >
                  <img src={obj.icon} alt={obj.title} className="objectif-icon" />
                  <span className="objectif-title">{obj.title}</span>
                </button>
              ))}
            </div>
          </div>
        );

      case STEPS.NOTIFICATIONS:
        return (
          <div className="step-container notifications-step">
            <h2>Activer les notifications</h2>
            <div className="notifications-content">
              <div className="notification-icon">
                <i className="fas fa-bell"></i>
              </div>
              <p className="notification-description">
                Ne manquez aucun message ! Activez les notifications pour être informé des nouveaux messages même lorsque l'application n'est pas ouverte.
              </p>
              {notificationPermission === false ? (
                <div className="notification-blocked">
                  <p className="notification-warning">
                    Les notifications sont bloquées par votre navigateur. 
                    Veuillez les autoriser dans les paramètres de votre navigateur pour recevoir des notifications.
                  </p>
                  <button className="skip-button" onClick={skipNotifications}>
                    Continuer sans notifications
                  </button>
                </div>
              ) : (
                <div className="notification-buttons">
                  <button 
                    className="enable-button"
                    onClick={handleNotificationToggle}
                  >
                    Activer les notifications
                  </button>
                  <button className="skip-button" onClick={skipNotifications}>
                    Plus tard
                  </button>
                </div>
              )}
            </div>
          </div>
        );

      case STEPS.RECAP:
        return (
          <div className="step-container recap-step">
            <h2>C'est l'heure des plaisirs éphémères !</h2>
            <div className="recap-content">
              <div className="recap-photo">
                <img src={formData.photoURL} alt="Profile" />
              </div>
              <div className="recap-info">
                <p><strong>Pseudo:</strong> {formData.pseudo}</p>
                <p><strong>Genre:</strong> {formData.genre === 'H' ? 'Homme' : 'Femme'}</p>
                <p><strong>Âge:</strong> {AGE_RANGES.find(r => r.value === formData.age)?.label}</p>
                <p><strong>Objectif:</strong> {OBJECTIFS.find(o => o.id === formData.objectif)?.title}</p>
              </div>
              <div className="ephemeral-message">
                <div className="ephemeral-icon">
                  <i className="fas fa-hourglass-half"></i>
                </div>
                <p>
                  Carpe Diem ! Profitez de l'instant présent car demain est un autre jour. 
                  Vos conversations disparaîtront dans 24h, laissant place à de nouvelles rencontres excitantes.
                </p>
                <p className="ephemeral-highlight">
                  La vie est courte, les plaisirs sont éphémères... Savourez chaque instant !
                </p>
              </div>
              <button 
                className="submit-button"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? 'Création...' : 'Commencer l\'aventure !'}
              </button>
            </div>
            {error && <div className="error-message">{error}</div>}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="create-account-screen">
      <div 
        className="progress-bar"
        style={{ 
          '--progress-percentage': currentStepIndex / (Object.keys(STEPS).length - 1)
        }}
      >
        {Object.values(STEPS).map(step => (
          <div 
            key={step}
            className={`progress-dot ${currentStepIndex >= step ? 'active' : ''}`}
          />
        ))}
      </div>
      {renderStep()}
    </div>
  );
}
