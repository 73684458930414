import React, { useState, useEffect, useRef } from 'react';
import { useChat } from '../contexts/ChatContext';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../services/firebase';
import './HomeScreen.css';

export default function HomeScreen() {
  const { setCurrentStep, setCurrentUser } = useChat();
  const [numero, setNumero] = useState('');
  const [pseudo, setPseudo] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPseudoInput, setShowPseudoInput] = useState(false);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const formRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    // Animation d'entrée
    const timer = setTimeout(() => {
      setShowWelcomeMessage(false);
    }, 2000);

    // Focus sur l'input
    if (inputRef.current) {
      inputRef.current.focus();
    }

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Réinitialiser le focus quand on change de champ
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPseudoInput]);

  const validateNumero = (num) => {
    return /^\d{1,4}$/.test(num);
  };

  const validatePseudo = (pseudo) => {
    return pseudo.length >= 2 && pseudo.length <= 20;
  };

  const handleNumeroChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 4);
    setNumero(value);
    setError('');
  };

  const handlePseudoChange = (e) => {
    const value = e.target.value.slice(0, 20);
    setPseudo(value);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (loading) return;
    
    setError('');
    setLoading(true);

    try {
      if (!showPseudoInput) {
        // Validation du numéro
        if (!validateNumero(numero)) {
          setError('Le numéro doit contenir entre 1 et 4 chiffres');
          setLoading(false);
          return;
        }

        // Vérifier si le numéro existe
        const numeroStr = numero.toString();
        const q = query(collection(db, 'utilisateurs'), where('numero', '==', numeroStr));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          // Le numéro existe, animer la transition vers le pseudo
          formRef.current?.classList.add('slide-left');
          setTimeout(() => {
            setShowPseudoInput(true);
            formRef.current?.classList.remove('slide-left');
            setLoading(false);
          }, 300);
        } else {
          // Le numéro n'existe pas, rediriger vers la création de compte
          const newUser = { numero: numeroStr };
          setCurrentUser(newUser);
          localStorage.setItem('chatState', JSON.stringify({ 
            currentUser: newUser, 
            currentStep: 'civilite' 
          }));
          
          formRef.current?.classList.add('slide-up');
          setTimeout(() => {
            setCurrentStep('civilite');
            setLoading(false);
          }, 300);
        }
      } else {
        // Validation du pseudo
        if (!validatePseudo(pseudo)) {
          setError('Le pseudo doit contenir entre 2 et 20 caractères');
          setLoading(false);
          return;
        }

        // Vérifier si le pseudo correspond au numéro
        const numeroStr = numero.toString();
        const q = query(
          collection(db, 'utilisateurs'),
          where('numero', '==', numeroStr),
          where('pseudo', '==', pseudo.toUpperCase())
        );
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          // Les informations sont correctes, animer la transition
          const userData = querySnapshot.docs[0].data();
          localStorage.setItem('chatState', JSON.stringify({ 
            currentUser: userData, 
            currentStep: 'participants' 
          }));
          
          formRef.current?.classList.add('slide-up');
          setTimeout(() => {
            setCurrentUser(userData);
            setCurrentStep('participants');
            setLoading(false);
          }, 300);
        } else {
          setError('Erreur le pseudo ne correspond pas au code');
          formRef.current?.classList.add('shake');
          setTimeout(() => {
            formRef.current?.classList.remove('shake');
          }, 500);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Une erreur est survenue. Veuillez réessayer.');
      formRef.current?.classList.add('shake');
      setTimeout(() => {
        formRef.current?.classList.remove('shake');
        setLoading(false);
      }, 500);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    formRef.current?.classList.add('slide-right');
    setTimeout(() => {
      setShowPseudoInput(false);
      setPseudo('');
      setError('');
      formRef.current?.classList.remove('slide-right');
    }, 300);
  };

  return (
    <div className="home-screen">
      <div className={`welcome-overlay ${showWelcomeMessage ? 'visible' : ''}`}>
        <img src="/resources/img/logo.png" alt="Logo" className="welcome-logo" />
      </div>

      <div className="background-shapes">
        <div className="shape shape-1"></div>
        <div className="shape shape-2"></div>
        <div className="shape shape-3"></div>
      </div>

      <div className="logo-container">
        <img src="/resources/img/logo.png" alt="Logo" className="logo" />
      </div>

      <form ref={formRef} onSubmit={handleSubmit} className="login-form" noValidate>
        {showPseudoInput && (
          <button type="button" onClick={handleBack} className="back-button">
            <i className="fa fa-fw fa-chevron-left"></i>
          </button>
        )}

        <div className="input-container">
          {!showPseudoInput ? (
            <>
              <input
                ref={inputRef}
                type="text"
                value={numero}
                onChange={handleNumeroChange}
                placeholder="Ton numéro"
                className="input-field"
                pattern="[0-9]*"
                inputMode="numeric"
                autoComplete="off"
                maxLength="4"
              />
              <div className="input-info">1 à 4 chiffres</div>
            </>
          ) : (
            <>
              <input
                ref={inputRef}
                type="text"
                value={pseudo}
                onChange={handlePseudoChange}
                placeholder="Ton pseudo"
                className="input-field"
                autoComplete="off"
                maxLength="20"
              />
              <div className="input-info">2 à 20 caractères</div>
            </>
          )}
        </div>

        <button 
          type="submit" 
          className={`button ${loading ? 'loading' : ''}`}
          disabled={loading}
        >
          {loading ? (
            <div className="button-loader"></div>
          ) : (
            !showPseudoInput ? "C'est parti !" : 'Se connecter'
          )}
        </button>

        {error && (
          <div className="error-message">
            <i className="fa fa-fw fa-exclamation-circle"></i>
            {error}
          </div>
        )}
      </form>

      <div className="version-info">
        TimeToMeet V.2.3.1
      </div>
    </div>
  );
}
