import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useChat } from '../contexts/ChatContext';
import { imageCache } from '../services/imageCache';
import { collection, query, getDocs, orderBy, where } from 'firebase/firestore';
import { db } from '../services/firebase';
import './ParticipantsScreen.css';

const OBJECTIFS = [
  { 
    id: 'cerise',
    title: 'Trouver sa moitiée',
    icon: '/resources/img/icons/cerise_photo.png',
    color: '#FF4458'
  },
  {
    id: 'fraise',
    title: 'Une soirée aux plaisirs sucrés',
    icon: '/resources/img/icons/fraise_photo.png',
    color: '#FF6B81'
  },
  {
    id: 'pasteque',
    title: 'Des calins sans pépins',
    icon: '/resources/img/icons/pasteque_photo.png',
    color: '#FF8E9E'
  },
  {
    id: 'raisin',
    title: 'Un verre sans se prendre la grappe',
    icon: '/resources/img/icons/raisin_photo.png',
    color: '#FFA1B0'
  }
];

const getObjectifEmoji = (objectif) => {
  switch (objectif?.toLowerCase()) {
    case 'fraise': return '🍓';
    case 'raisin': return '🍇';
    case 'pasteque': return '🍉';
    case 'cerise': return '🍒';
    default: return '🍒';
  }
};

const getObjectifTitle = (objectif) => {
  const found = OBJECTIFS.find(obj => obj.id === objectif?.toLowerCase());
  return found ? found.title : '';
};

const getAgeRange = (age) => {
  switch (age) {
    case '18-25': return '18-25 ans';
    case '25-35': return '25-35 ans';
    case '35-45': return '35-45 ans';
    case '45+': return '45+ ans';
    default: return '';
  }
};

const ParticipantCard = React.memo(({ participant, photo, onClick, index, hasUnreadMessages }) => {
  console.log('Full participant data:', participant);
  const genderClass = participant?.genre === 'H' ? 'male' : participant?.genre === 'F' ? 'female' : '';
  const randomDelay = -1 * (Math.random() * 2); // Random delay between 0 and 2 seconds
  
  return (
    <div 
      className="participant-card" 
      onClick={onClick}
      style={{
        animationDelay: `${randomDelay}s`
      }}
    >
      <div className="participant-avatar-container">
        <img
          src={photo || participant.photoURL || '/resources/img/avatar-default.png'}
          alt={participant.pseudo}
          className={`participant-avatar ${genderClass}`}
          onError={(e) => {
            e.target.src = '/resources/img/avatar-default.png';
          }}
        />
        {hasUnreadMessages && <div className="notification-dot"></div>}
        <div className="location-indicator">
          <span className="objectif-emoji">{getObjectifEmoji(participant.objectif)}</span>
        </div>
      </div>
      <div className="participant-name">{participant.pseudo}</div>
    </div>
  );
});

const ParticipantPopup = React.memo(({ participant, onClose, onChat }) => {
  const [photoURL, setPhotoURL] = useState(participant.photoURL || '/resources/img/avatar-default.png');
  
  useEffect(() => {
    const loadImage = async () => {
      if (!participant.photoURL) return;
      
      try {
        const cachedURL = await imageCache.getImage(participant.photoURL);
        if (cachedURL) {
          setPhotoURL(cachedURL);
        }
      } catch (error) {
        console.error('Error loading photo:', error);
        setPhotoURL('/resources/img/avatar-default.png');
      }
    };
    
    loadImage();
  }, [participant.photoURL]);

  const handleChat = useCallback(() => {
    onChat(participant);
  }, [onChat, participant]);

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={e => e.stopPropagation()}>
        <div className="popup-header">
          <h2>{participant.pseudo}</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        
        <div className="popup-body">
          <div className="popup-photo">
            <img 
              src={photoURL}
              alt={participant.pseudo} 
              onError={(e) => {
                e.target.src = '/resources/img/avatar-default.png';
              }}
            />
          </div>
          
          <div className="participant-details">
            <div className="detail-item">
              <i className={`fa ${participant.genre === 'H' ? 'fa-mars' : 'fa-venus'}`}></i>
              <span className={participant.genre === 'H' ? 'text-male' : 'text-female'}>{getAgeRange(participant.age)}</span>
            </div>
            
            <div className="detail-item">
            <span className="objectif-emoji">{getObjectifEmoji(participant.objectif)}</span>
              <span>{getObjectifTitle(participant.objectif)}</span>
            </div>

            {participant.description && (
              <div className="detail-item description">
                <i className="fa fa-comment"></i>
                <span>{participant.description}</span>
              </div>
            )}
          </div>
        </div>
        
        <div className="popup-footer">
          <button className="popup-button secondary" onClick={onClose}>
            Fermer
          </button>
          <button className="popup-button primary" onClick={handleChat}>
            Discuter
          </button>
        </div>
      </div>
    </div>
  );
});

export default function ParticipantsScreen() {
  const { currentUser, setCurrentStep, setSelectedClient, clients, startUsersListener } = useChat();
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [participantPhotos, setParticipantPhotos] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [unreadMessages, setUnreadMessages] = useState({});

  const loadParticipantPhotos = useCallback(async () => {
    const photos = { ...participantPhotos };
    const newParticipants = clients.filter(
      p => !photos[p.pseudo] && p.photoURL && p.pseudo !== currentUser?.pseudo
    );

    if (newParticipants.length === 0) {
      setIsLoading(false);
      return;
    }

    try {
      await Promise.all(
        newParticipants.map(async (participant) => {
          if (!participant.photoURL) return;
          
          try {
            const cachedURL = await imageCache.getImage(participant.photoURL);
            if (cachedURL) {
              photos[participant.pseudo] = cachedURL;
            } else {
              photos[participant.pseudo] = participant.photoURL;
              await imageCache.addImage(participant.photoURL);
            }
          } catch (error) {
            console.error('Error loading photo for:', participant.pseudo, error);
          }
        })
      );

      setParticipantPhotos(photos);
    } catch (error) {
      console.error('Error loading participant photos:', error);
    } finally {
      setIsLoading(false);
    }
  }, [clients, currentUser?.pseudo, participantPhotos]);

  const checkUnreadMessages = useCallback(async () => {
    if (!currentUser?.pseudo) return;
    
    const unreadMap = {};
    const currentUserUpper = currentUser.pseudo.toUpperCase();
    
    try {
      // Get all chats
      const chatsRef = collection(db, 'chats');
      const chatsSnapshot = await getDocs(chatsRef);
      
      // Process each chat
      for (const chatDoc of chatsSnapshot.docs) {
        const chatId = chatDoc.id;
        if (!chatId.includes(currentUserUpper)) continue;
        
        const otherParticipantPseudo = chatId.split('_')
          .find(p => p !== currentUserUpper)
          ?.toLowerCase();
          
        if (!otherParticipantPseudo) continue;
        
        // Get messages
        const messagesRef = collection(db, 'chats', chatId, 'messages');
        const messagesQuery = query(messagesRef, orderBy('timestamp', 'desc'));
        const messagesSnapshot = await getDocs(messagesQuery);
        
        const messages = messagesSnapshot.docs.map(doc => doc.data());
        
        // Check for unread messages from this participant
        const hasUnread = messages.some(msg => 
          msg.sender.toUpperCase() === otherParticipantPseudo.toUpperCase() && 
          (!msg.see || msg.see !== 1)
        );
        
        if (hasUnread) {
          unreadMap[otherParticipantPseudo] = true;
        }
      }
      
      setUnreadMessages(unreadMap);
    } catch (error) {
      console.error('Error checking unread messages:', error);
    }
  }, [currentUser?.pseudo]);

  useEffect(() => {
    checkUnreadMessages();
  }, [checkUnreadMessages]);

  useEffect(() => {
    loadParticipantPhotos();
  }, [loadParticipantPhotos]);

  useEffect(() => {
    const unsubscribe = startUsersListener();
    return () => unsubscribe?.();
  }, [startUsersListener]);

  const handleStartChat = useCallback((participant) => {
    setSelectedClient(participant);
    setCurrentStep('chat');
  }, [setSelectedClient, setCurrentStep]);

  const filteredClients = useMemo(() => 
    clients.filter(client => client.pseudo !== currentUser?.pseudo),
    [clients, currentUser?.pseudo]
  );

  const columns = useMemo(() => {
    const result = {
      left: [],
      center: [],
      right: []
    };

    filteredClients.forEach((participant, index) => {
      const columnIndex = index % 3;
      if (columnIndex === 0) result.left.push(participant);
      else if (columnIndex === 1) result.center.push(participant);
      else result.right.push(participant);
    });

    return result;
  }, [filteredClients]);

  const renderColumn = useCallback((participants, columnIndex) => (
    participants.map((participant, index) => (
      <ParticipantCard
        key={participant.pseudo}
        participant={participant}
        photo={participantPhotos[participant.pseudo]}
        onClick={() => setSelectedParticipant(participant)}
        index={columnIndex * participants.length + index}
        hasUnreadMessages={participant.pseudo ? unreadMessages[participant.pseudo.toLowerCase()] : false}
      />
    ))
  ), [participantPhotos, setSelectedParticipant, unreadMessages]);

  if (isLoading) {
    return (
      <div className="participants-screen">
        <div className="loading-spinner">Chargement des participants...</div>
      </div>
    );
  }

  return (
    <div className="participants-screen">
      <div className="participants-container">
        <div className="participants-columns">
          <div className="participants-column left">
            {renderColumn(columns.left, 0)}
          </div>
          <div className="participants-column center">
            {renderColumn(columns.center, 1)}
          </div>
          <div className="participants-column right">
            {renderColumn(columns.right, 2)}
          </div>
        </div>
      </div>

      {selectedParticipant && (
        <ParticipantPopup
          participant={selectedParticipant}
          onClose={() => setSelectedParticipant(null)}
          onChat={handleStartChat}
        />
      )}
    </div>
  );
}
